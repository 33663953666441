<template>
  <v-container>
    <v-row v-if="!isSending">
      <v-col>
        <calendesk-information-message class="mt-4">
          {{ $trans("fakturownia_description") }}<br /><br />
          {{ $trans("fakturownia_description_2") }}
        </calendesk-information-message>
        <v-form ref="form" v-model="form" @submit.prevent="handleSave">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="isEnabled"
                  hide-details
                  :disabled="isSavingSettings"
                  color="primary"
                  :label="$trans('fakturownia_enabled_title')"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  v-model="sendInvoice"
                  hide-details
                  :disabled="isSavingSettings"
                  color="primary"
                  :label="$trans('fakturownia_send_invoice_title')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="apiToken"
                  :disabled="isSavingSettings"
                  hide-details="auto"
                  :label="$trans('fakturownia_api_token_title')"
                  :rules="[rules.required, rules.maxChars(255)]"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="accountUrl"
                  :disabled="isSavingSettings"
                  hide-details="auto"
                  :label="$trans('fakturownia_account_url_title')"
                  :rules="[rules.required, rules.maxChars(255), rules.url]"
                  placeholder="https://calendesk.fakturownia.pl"
                  outlined
                  @blur="fixAccountUrl"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="gtuCodes"
                  :items="$helpers.availableGtuCodes"
                  multiple
                  small-chips
                  :disabled="isSavingSettings"
                  hide-details="auto"
                  :label="$trans('fakturownia_gtu_codes_title')"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row v-if="wasInitiallyEnabled">
              <v-col class="d-flex align-center">
                <v-btn
                  color="error"
                  outlined
                  :disabled="isSavingSettings"
                  text
                  @click="handleDisconnect"
                >
                  <span v-text="$trans('disconnect_button_title')" />
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import {
  maxChars,
  required,
  url,
} from "@/lib/calendesk-js-library/forms/validators";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "FakturowniaIntegration",
  components: {
    CalendeskInformationMessage,
  },
  mixins: [sharedActions, planActions],
  data() {
    return {
      form: null,
      buttonLoading: false,
      isEnabled: false,
      apiToken: null,
      gtuCodes: null,
      accountUrl: null,
      isSavingSettings: false,
      sendInvoice: true,
      wasInitiallyEnabled: false,
      rules: {
        required,
        maxChars,
        url,
      },
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
  },
  created() {
    if (this.fakturowniaPackage && this.fakturowniaPackage.config) {
      this.isEnabled = this.fakturowniaPackage.config.is_enabled;
      this.accountUrl = this.fakturowniaPackage.config.account_url;
      this.apiToken = this.fakturowniaPackage.config.api_token;
      this.sendInvoice = this.fakturowniaPackage.config.send_invoice;
      this.gtuCodes = this.fakturowniaPackage.config.gtu_codes || null;

      this.wasInitiallyEnabled =
        this.isEnabled && this.accountUrl && this.apiToken;
    }
  },
  methods: {
    ...mapActions({
      updatePackageConfiguration: "packages/updateConfiguration",
      fetchCurrentTenantPlan: "packages/fetchCurrentTenantPlan",
      validateFakturowniaSettings: "packages/validateFakturowniaSettings",
    }),
    fixAccountUrl() {
      if (this.accountUrl && this.accountUrl.includes("http://")) {
        this.accountUrl = this.accountUrl.replace("http://", "https://");
      }
    },
    handleSave() {
      if (!this.$refs.form.validate()) {
        errorNotification("form_is_invalid", {}, false);
        return;
      }

      this.fixAccountUrl();
      this.isSavingSettings = true;
      this.$emit("ctaButtonLoading", true);

      this.validateFakturowniaSettings({
        api_token: this.apiToken,
        account_url: this.accountUrl,
      })
        .then(() => {
          if (this.isEnabled) {
            pushEvent("enableFakturowniaIntegration");
          } else {
            pushEvent("disableFakturowniaIntegration");
          }

          this.updatePackageConfiguration({
            id: this.fakturowniaPackage.id,
            config: {
              is_enabled: this.isEnabled,
              api_token: this.apiToken,
              account_url: this.accountUrl,
              send_invoice: this.sendInvoice,
              gtu_codes: this.gtuCodes,
            },
          })
            .then(() => {
              this.fetchCurrentTenantPlan()
                .then(() => {
                  this.$emit("close");
                  successNotification("update_success");
                })
                .finally(() => {
                  this.isSavingSettings = false;
                });
            })
            .catch((error) => {
              this.isSavingSettings = false;
              this.$emit("ctaButtonLoading", false);
              errorNotification(null, error);
            });
        })
        .catch((error) => {
          errorNotification("fakturownia_validation_error", error, false);
          this.isSavingSettings = false;
          this.$emit("ctaButtonLoading", false);
        });
    },
    handleDisconnect() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmationText: this.$trans("disconnect_button_title"),
        confirmationButtonColor: "error",
        hideImportantInformation: true,
        confirmAction: () => {
          this.setCommonDialogLoader(true);

          this.updatePackageConfiguration({
            id: this.fakturowniaPackage.id,
            config: {
              is_enabled: false,
              api_token: null,
              account_url: null,
              send_invoice: this.sendInvoice,
              gtu_codes: this.gtuCodes,
            },
          })
            .then(() => {
              this.fetchCurrentTenantPlan()
                .then(() => {
                  this.$emit("close");
                  successNotification("update_success");
                })
                .finally(() => {
                  this.setCommonDialogLoader(false);
                  this.closeConfirmDialog();
                  this.$emit("close");
                });
            })
            .catch((error) => {
              this.isSavingSettings = false;
              errorNotification(null, error);
            });
        },
      });
    },
  },
};
</script>
